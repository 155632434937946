import {round, addEventListener, removeEventListener} from '../utils/utils.js';

export default function fidPolyfill([window], entryType) {
    return new Promise(resolve => {
        // Use polyfill
        const eventTypes = [
            'pointerdown',
            'click',
            'mousedown',
            'keydown',
            'touchstart'
        ];
        eventTypes.forEach(eventType => addEventListener(window, eventType, onInput, false));

        function onInput({type, timeStamp, cancelable}) {
            if (!cancelable) {
                return;
            }

            const now = performance.now();
            const end = timeStamp > 1e12 ? Date.now() : now;
            const duration = end - timeStamp;
            const startTime = now - duration;

            if (type === eventTypes[0]) {
                onPointerDown(type, startTime);
            } else {
                notify(type, startTime);
            }
        }

        function notify(action, startTime) {
            const delay = round(performance.now() - startTime);
            eventTypes.forEach(eventType => removeEventListener(window, eventType, onInput, false));
            resolve({
                entryType,
                action,
                startTime: round(startTime),
                delay
            });
        }

        function onPointerDown(action, startTime) {
            addEventListener(window, 'pointerup', onPointerUp, false);
            addEventListener(window, 'pointercancel', removePointerEventListeners, false);

            function onPointerUp() {
                notify(action, startTime);
                removePointerEventListeners();
            }

            function removePointerEventListeners() {
                removeEventListener(window, 'pointerup', onPointerUp, false);
                removeEventListener(window, 'pointercancel', removePointerEventListeners, false);
            }
        }
    });
}
